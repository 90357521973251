import { sriClient } from 'ReduxLoop/utils/apiConfig';

export const getPossibleStudyProgrammes = async () => {
  const studyProgrammeTypes = await sriClient.getAll('/sam/commons/studyprogrammes/types', {
    codeIn: 'BASISOPTIE,CUSTOM,STUDIERICHTING',
  });
  
  const finalitiesHrefs = {
    // There are 3 studyprogrammegroups for 7de jaar:
    '/sam/commons/studyprogrammegroups/2a498a7c-fa7c-4b0c-8658-08201ed67d0b': 7, // 7de leerjaar Hoger Onderwijs
    '/sam/commons/studyprogrammegroups/d350d5a0-7eea-493e-beb6-60f5b654e21c': 7, // 7de leerjaar na OK4
    '/sam/commons/studyprogrammegroups/e8a6d467-5f94-4b7f-add5-7e7b8ce79b15': 7, // 7de leerjaar na OK3
  }
  
  const gradeHrefs = {
    '/sam/commons/studyprogrammegroups/b1a7f179-6e7c-497b-be9a-7ca30e4e8c65': 1, // graad 1
    '/sam/commons/studyprogrammegroups/2ea33583-8819-4137-bb4e-c3efd9e91346': 2, // graad 2
    '/sam/commons/studyprogrammegroups/954369e2-99c1-4ef4-be19-f5496956a270': 3, // graad 3
  };

  const finalitiesHrefsKeys = Object.keys(finalitiesHrefs);
  const gradeHrefsKeys = Object.keys(gradeHrefs);

  const getStudyProgrammeGrade = (studyProgramme) => {
    // Some studyprogrammes are shared among graad 3 and 7de jaar.
    // So, if we found the stuyprogramme matches with one of the finalities...
    const finalityProgrammeGroup = studyProgramme.studyProgrammeGroups.find((group) =>
      finalitiesHrefsKeys.includes(group.studyProgrammeGroup.href)
    );
  
    // ...we return it inmediatelly.
    if(finalityProgrammeGroup) {
      return finalitiesHrefs[finalityProgrammeGroup.studyProgrammeGroup.href].toString()
    }

    // Otherwise, we look for the grade of the studyprogramme (1, 2 or 3)...
    const gradeProgrammeGroup = studyProgramme.studyProgrammeGroups.find((group) =>
      gradeHrefsKeys.includes(group.studyProgrammeGroup.href)
    );

    // ...and return it safely.
    return gradeProgrammeGroup ? gradeHrefs[gradeProgrammeGroup.studyProgrammeGroup.href].toString() : '';
  };

  const studyProgrammes = await sriClient.getAll('/sam/commons/studyprogrammes', {
    expand: 'FULL',
    type: studyProgrammeTypes.map((studyProgrammeType) => studyProgrammeType.$$meta.permalink),
  });

  return studyProgrammes.map((studyProgramme) => {
    return {
      ...studyProgramme,
      grade: getStudyProgrammeGrade(studyProgramme),
    };
  });
};

export const getStudyProgrammes = async (ouPermalink) => {
  return sriClient.getAll('/sam/educationalprogrammedetails', {
    organisationalUnit: ouPermalink,
    hasStudyProgramme: true,
    expand: 'FULL',
  });
};

export async function addStudyProgrammeToSchool(studyProgrammeRelation) {
  return sriClient.put(
    `/sam/educationalprogrammedetails/${studyProgrammeRelation.key}`,
    studyProgrammeRelation
  );
}

export async function deleteStudyProgrammeFromSchool(studyProgrammeRelationPermalink) {
  return sriClient.delete(studyProgrammeRelationPermalink);
}
